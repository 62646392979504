<template>
  <v-menu
    bottom
    :close-on-click="true"
  >
    <template #activator="{ on }">
      <v-btn
        text
        v-on="on"
        >
      <!-- Visualizza nome (Edit account setting) oppure Accedi -->
        <v-icon left>
          mdi-account-outline
        </v-icon>
        {{ fullName }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        @click="$router.push({name: 'showUserProfile'})"
      >
        <v-list-item-title>{{ $t('profile.user_setting') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        @click="$router.push({name: 'showOrganizationProfile'})"
      >
        <v-list-item-title>{{ $t('profile.organization_settings') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isChildCustomersAnchorVisible"
        @click="$router.push({name: 'childOrganizations'})"
      >
        <v-list-item-title>{{ $t('profile.child_organizations') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isInvoiceAccountAnchorVisible"
        @click="$router.push({name: 'showInvoicingProfile'})"
      >
        <v-list-item-title>{{ $t('profile.invoice_settings') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isLedgerBookAnchorVisible"
        @click="$router.push({name: 'showLedgerBook'})"
      >
        <v-list-item-title>{{ $t('profile.ledger_book') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isConservazioneDigitaleAnchorVisible"
        @click="$router.push({name: 'showConservazioneDigitale'})"
        >
        <v-list-item-title>{{ $t('profile.conservazione_digitale') }}</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="isApiPasswordAnchorVisible"
        @click="$router.push({name: 'showApiPassword'})"
      >
        <v-list-item-title>{{ $t('profile.api_password') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SettingsSelector',

  props: {
    fullName: String,
    role: String,
  },

  computed: {
    ...mapState('organization', ['organization']),
    ...mapGetters('profile', ['currentUser']),
    isChildCustomersAnchorVisible() {
      return this.currentUser?.abilities?.canManageChildCustomers
    },
    isInvoiceAccountAnchorVisible() {
      return this.currentUser?.abilities?.canUpdateInvoiceAccount
    },
    isLedgerBookAnchorVisible() {
      return this.currentUser?.abilities?.canReadLedgerBook
    },
    isConservazioneDigitaleAnchorVisible() {
      // TODO: use abilities
      return this.role === 'admin'
    },
    isApiPasswordAnchorVisible() {
      // TODO: use abilities & check them also in Api::Ui::V1::CustomersController -> new_api_password action
      return this.role === 'admin' &&
        this.organization?.only_free_subscriptions === false &&
        (this.currentUser.impersonating || !this.organization.has_parent_customer)
    },
  },
}
</script>
