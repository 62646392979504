<template>
  <v-app-bar
    app
    :dark="isThemeDark"
    :color="organizationBackgroundColor"
    elevate-on-scroll
    class="horizontal-nav-bar"
    :style="{'border-bottom': '1px solid' + headerBorderColor + '!important'}"
  >
    <v-toolbar-title>
      <router-link
        :to="{ name: 'Dashboard' }"
        tag="button"
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col>
            <v-img
              v-if="organizationLogo"
              :src="organizationLogo"
              height="64"
              width="115"
              contain
            />
          </v-col>
          <!--v-col>
            <span class="display-1">{{ organizationName }}</span>
          </v-col-->
        </v-row>
      </router-link>
    </v-toolbar-title>

    <v-spacer />
    <v-toolbar-items class="">
      <!-- Bottoni loggato -->
      <!-- Bottoni loggato -->
      <v-btn
        v-if="isLoggedIn"
        :to="{name: &quot;DossiersIndex&quot;, query: {page: 1}}"
        text
      >
        {{ $t('sidebar.dossiers') }}
      </v-btn>
      <v-btn
        v-if="isLoggedIn"
        :to="{name: &quot;SignersIndex&quot;, query: {page: 1}}"
        text
      >
        {{ $t('sidebar.signers') }}
      </v-btn>
      <v-btn
          v-if="isUsersAnchorVisible"
          :to="{name: 'Accounts'}"
          text
      >
        {{ $t('sidebar.accounts') }}
      </v-btn>
      <v-icon v-if="isLoggedIn">
        mdi-drag-vertical
      </v-icon>
      <!-- Fine bottoni loggato -->

      <!-- Visualizza nome (Edit account setting) oppure Accedi -->
      <SettingsSelector v-if="isLoggedIn" :fullName="fullName" :role="currentUser.role"/>
      <v-btn
        v-else-if="!isUiForSigning"
        :to="{name: &quot;login&quot;}"
        text
      >
        {{ $t('sidebar.login') }}
      </v-btn>
      <!-- Fine -->


      <!-- Bottone selettore lingua -->
      <LocaleSelector />

    </v-toolbar-items>
    <!--v-app-bar-nav-icon
      v-if="canUseHomepage"
      @click.stop="toggleSidebar"
    /-->
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LocaleSelector from './locale_selector'
import SettingsSelector from './settings_selector'
import { homepageContentMixin } from '../../mixins/homepage_content_mixin'

export default {
  name: 'Header',

  components: {
    LocaleSelector,
    SettingsSelector,
  },

  mixins: [homepageContentMixin],

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('profile', [
      'fullName',
      'currentUser',
    ]),
    ...mapState('application', ['application', 'current_organization']),
    ...mapState('organization', ['organization']),
    isUsersAnchorVisible() {
      return this.isLoggedIn && this.currentUser?.abilities?.canManageUsers
    },
    headerBorderColor () {
      if (this.isLoggedIn && this.currentOrganization.whitelabel) {
        return this.currentOrganization.theme.primary_color
      } else {
        return '#2e844c'
      }
    },
    organizationName () {
      return this.application.name // this.currentOrganization.name
    },
    isUiForSigning () {
      return this.$route.path.includes('/sign/')
    },
    organizationLogo () {
      if (this.currentOrganization.whitelabel && this.currentOrganization.logo_url) {
        console.log(this.currentOrganization.logo_url)
        return this.organization.logo_url
      }
      return require(`../../../../assets/images/${this.organizationLogoSquareName}`)
    },
    organizationBackgroundColor () {
      // if (this.current_organization.logo) console.log('ATTENZIONE: caricare il logo per la compagnia ', this.current_organization.id)
      //console.log(this.currentOrganization.whitelabel)
      if (!this.isLoggedIn || !this.currentOrganization.whitelabel)
        return '#fff'
      return this.currentOrganization.theme?.header_background_color || 'white' // || '../../../../../../app/assets/images/img-logo.svg'
    },
    isThemeDark () {
      // return ["white", "#fff", "#ffffff"].includes(this.current_organization.theme.color)
      //      if (!this.isLoggedIn || this.currentOrganization.whitelabel == false) return false
      if (!this.isLoggedIn || !this.currentOrganization.whitelabel) return false
      return this.currentOrganization.theme?.header_dark
    },
    currentOrganization () {
      return this.organization?.name ? this.organization : this.current_organization
    },
    currentLocale () {
      return this.$i18n.locale
    },
  },

  methods: {
    /*toggleSidebar: function () {
      this.$store.dispatch('layout/toggle_sidebar')
    },*/
  },
}
</script>

<style lang='scss'>

</style>
