import Vue from 'vue'

export const namespaced = true

export const state = {
  // countries: [],
  // regions: [],
  phonePrefixes: [],
}

export const mutations = {
  // SET_COUNTRIES(state, countries) {
  //   state.countries = countries
  // },
  // SET_REGIONS(state, regions) {
  //   state.regions = regions
  // },
  SET_PHONE_PREFIXES (state, phonePrefixes) {
    state.phonePrefixes = phonePrefixes
  },
}

export const actions = {
  fetchSmsProviders ({ commit, dispatch, rootState }, searchAttrs) {
    let url = `${rootState.application.urls.sms_providers_index}?`
    return Vue.http.get(url, null, {
      responseType: 'json',
    }).then(response => {
      return response.body
    }).catch(error => {
      console.log('fetchSmsProviders There was an error:', error.response ? error.response : error)
      return error
    })
  },
  fetchCountries ({ commit, dispatch, rootState }, searchAttrs) {
    let url = `${rootState.application.urls.countries_index}?`
    const urlParams = []
    if (searchAttrs.name) urlParams.push(`name=${searchAttrs.name}`)
    if (searchAttrs.multilanguage) urlParams.push(`multilanguage=${searchAttrs.multilanguage}`)
    url = `${url}${urlParams.join('&')}`
    // console.log('fetchCountries with params', urlParams, url)
    return Vue.http.get(url, null, {
      responseType: 'json',
    }).then(response => {
      const countries = response.data
      // commit('SET_COUNTRIES', countries)
      return countries
    }).catch(error => {
      console.log('fetchCountries There was an error:', error.response ? error.response : error)
      return error
    })
  },
  fetchRegions ({ commit, dispatch, rootState }, { country_id, searchAttrs }) {
    let url = `${rootState.application.urls.regions_index.replace(':country_id', country_id)}?`
    const urlParams = []
    if (searchAttrs.name) urlParams.push(`name=${searchAttrs.name}`)
    // if (!!searchAttrs.id) urlParams.push(`id=${searchAttrs.id}`)
    url = `${url}${urlParams.join('&')}`
    console.log('fetchRegions', url)
    return Vue.http.get(url, null, {
      responseType: 'json',
    }).then(response => {
      const regions = response.data
      // commit('SET_REGIONS', regions)
      return regions
    }).catch(error => {
      console.log('fetchRegions There was an error:', error.response ? error.response : error)
      return error
    })
  },
  fetchCities ({ commit, dispatch, rootState }, { country_id, searchAttrs }) {
    let url = `${rootState.application.urls.cities_index.replace(':country_id', country_id)}?`
    const urlParams = []
    if (searchAttrs.name) urlParams.push(`name=${searchAttrs.name}`)
    if (searchAttrs.region_id) urlParams.push(`region_id=${searchAttrs.region_id}`)
    url = `${url}${urlParams.join('&')}`
    console.log('fetchCities', url)
    return Vue.http.get(url, null, {
      responseType: 'json',
    }).then(response => {
      const cities = response.data
      // commit('SET_CITIES', cities)
      return cities
    }).catch(error => {
      console.log('fetchCities There was an error:', error.response ? error.response : error)
      return error
    })
  },
  fetchPhonePrefixes ({ commit, rootState }, searchAttrs) {
    let url = `${rootState.application.urls.phone_prefixes}?`
    const urlParams = []
    if (searchAttrs.name) urlParams.push(`name=${encodeURIComponent(searchAttrs.name)}`)
    if (searchAttrs.id) urlParams.push(`id=${encodeURIComponent(searchAttrs.id)}`)
    url = `${url}${urlParams.join('&')}`
    console.log('fetchPhonePrefixes', url)
    return Vue.http.get(url, null, {
      responseType: 'json',
    }).then(response => {
      const phone_prefixes = response.data
      commit('SET_PHONE_PREFIXES', phone_prefixes)
      return phone_prefixes
    }).catch(error => {
      console.log('fetchPhonePrefixes There was an error:', error.response ? error.response : error)
      return error
    })
  },
}

export const getters = {
}
