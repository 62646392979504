<template>
  <v-container class="center">
    <div class="text-center">
      <h1 class="display-3">
        Uh oh! 🔌
      </h1>
      <h3>{{ message }}</h3>
      <v-btn
        class="mt-5"
        @click="$router.go(-1)"
      >
        {{ $t('commons.retry') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'NetworkError',

  props: {
    message_string: {
      type: String,
    },
    message_key: {
      type: String,
      default: 'generic_api_error',
    },
  },

  computed: {
    message () {
      return this.message_string || this.$t(`alerts.${this.message_key}`)
    },
  },
}
</script>

<style scoped lang="scss">
  .center {
    display: grid;
    place-items: center;
    height: 70vh;
  }
</style>
