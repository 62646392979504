import moment from 'moment'

export const utilityMixin = {
  methods: {
    formattedDate (date, format = 'L') {
      return date ? moment(date, 'YYYY-MM-DD').format(format) : null
    },
    formattedTime (time, outFormat = 'HH:mm') {
      return time ? moment(time).format(outFormat) : ''
    },
    formattedDateTime (datetime, outFormat = 'LLLL') {
      return datetime ? moment(datetime).format(outFormat) : null
    },
    // Return difference in milliseconds, example:
    // $vm0.differenceDateTime("2020-09-15T16:46:01.000+02:00", "2020-09-15T16:47:01.000+02:00") => 60000 (60 seconds)
    // Omitting the "to" datetime, the current datetime is used
    // $vm0.differenceDateTime("2020-09-15T16:46:01.000+02:00") => Now - param date => so the result is positive if param date is in the past
    differenceDateTime (from, to = null) {
      if (!to) to = moment()
      return moment(to).diff(moment(from))
    },
    // Used to show translated error below the form's field
    show_error_form_field (errors) {
      // return errors.map(error => this.isObject(error) ? this.$t(`errors.${error.error}`) : this.$t(`errors.${error}`) ).join(' - ')
      return errors.map(error => this.isObject(error) ? error.error : error).join(' - ')
    },
    getMessageFromResponse (error) {
      return error?.body
        ? (error.body?.errors ? Object.entries(error.body.errors).map(([k, v]) => `${k} ${v}`).join(', ') : error.body?.error || error.body)
        : error
    },
    getI18nMessageFromResponse ({ error, resource, default_label_error = 'generic_save_error' }) {
      if (error?.body) {
        return (error.body?.errors
          ? Object.entries(error.body.errors).map(([k, v]) => {
            const field = this.$t(`${resource}.attributes.${k}`)
            return `${field}: ${v}`
          }).join(', ')
          : error.body?.error || error.body)
      } else {
        console.error(error)
        return this.$t(`alerts.${default_label_error}`)
      }
    },
    isEmptyObject (obj) {
      // return Object.keys(obj ?? {}).length === 0
      let name
      for (name in obj) { return false }
      return true
    },
    isArray (value) {
      return value && typeof value === 'object' && value.constructor === Array
    },
    isFunction (value) {
      return typeof value === 'function'
    },
    isObject (value) {
      return value && typeof value === 'object' && value.constructor === Object
    },
    labelsPrefix () {
      return 'commons.labels' // override in component if needed
    },
    // Params:
    // validation_field: pass the key used in vuelidate if it does not match with the label (ex: user vs user_id)
    // required: if true and the field is required, a * after the label is appended
    // optional: if true and the field is not required, a [optional] after the label is appended (the tag is localized)
    labelFor (field, { validation_field = field, required = false, optional = false, resource = this.labelsPrefix() } = {}) {
      let label = this.$t(`${resource}.attributes.${field}`)
      if (this.isRequired(validation_field)) {
        if (required) label += ' *'
      } else {
        if (optional) label += ` [${this.$t('commons.optional')}]`
      }
      return label
    },
    hintFor (field, { resource = this.labelsPrefix() } = {}) {
      return this.$t(`${resource}.hints.${field}`)
    },
    isRequired (field) {
      // If the field in vuelidate is present, different objects may exist in different paths and
      // the choice of the correct one unfortunately complicates the following code ...
      if (!!this.$v && !!this.$v[field] && (!!this.$v[field].$params.required &&
        (this.$v[field].$params.required.type == 'required' ||
          (this.$v[field].$params.required.type == 'requiredIf' && this.$v[field].$params.required.prop())))) {
        return true
      } else {
        return false
      }
    },
    numberFormatter (value, { locale = 'it', currency = 'EUR' } = {}) {
      // Create our number formatter.
      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value)
    },
    plusNumberFormatterIfNotZero (value, { locale = 'it', currency = 'EUR' } = {}) {
      if (value == 0) {
        return ""
      }
      return `+${Intl.NumberFormat(locale, { style: 'currency', currency }).format(value)}`
    },
    isCatchedError (status, { exclude_states = [] } = {}) {
      if (!status) return
      return [500, 401, 502, 503, 504].filter(code => !exclude_states.includes(code)).includes(status)
    },
    formatPhoneNumber (prefix, phone) {
      if (!prefix || !phone) return ''
      return `+${prefix} ${phone}`
    },
    hasRoutePath (path) {
      return this.$route.fullPath.includes(path)
    },
  },
}
