import Vue from 'vue'

export const namespaced = true

export const state = {
  products: [],
}

export const mutations = {
  SET_PRODUCTS (state, products) {
    state.products = products
  },
}

export const actions = {
  fetchProducts ({ commit, dispatch, rootState }, params = { page: 1, per_page: 50, sort_by: null, sort_desc: null, search: null }) {
    return new Promise((resolve, reject) => {
      dispatch('layout/set_loading', true, { root: true })
      // console.log(`fetchProducts rootState ${rootState.application.urls.products_index}`)
      const arParams = Object.keys(params)
        .filter(p => params[p] != null)
        .map(p => `${p}=${params[p]}`)
      // let url = `${rootState.application.urls.products_index}?page=${params.page}&per_page=${params.perPage}`
      let url = rootState.application.urls.products_index
      if (arParams.length > 0) url += `?${arParams.join('&')}`
      Vue.http.get(url, null, {
        responseType: 'json',
      }).then(response => {
        const result = response.data
        commit('SET_PRODUCTS', result.products)
        dispatch('layout/set_loading', false, { root: true })
        resolve(result.products)
      }).catch(error => {
        dispatch('layout/set_loading', false, { root: true })
        console.log('fetchProducts There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
}

export const getters = {
  getProductById: state => id => {
    return state.products.find(product => product.id == id)
  },
}
