import dayjs from 'dayjs'

import 'dayjs/locale/it'
import 'dayjs/locale/en'

export default {
  install: function (Vue, options) {
    Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs })
  },
}
