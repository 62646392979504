import Vue from 'vue'

export const namespaced = true

export const state = {
}

export const mutations = {
}

export const actions = {
  getLedger ({ commit, dispatch, rootState }, { sort_by, sort_desc, page, per_page, from, to, ledger_page }) {
    return new Promise((resolve, reject) => {
      Vue.http.get(rootState.application.urls.ledgers_index,
        {
          params: {
            sort_by, sort_desc, page, per_page, from, to, ledger_page
          },
        })
        .then(response => {
          resolve(response.body)
        }, error => {
          reject(error)
        })
    })
  },
}

export const getters = {
}
