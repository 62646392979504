import Vue from 'vue'

export const namespaced = true

export const state = {
  user: {},
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
}

export const actions = {
  fetchUser ({ commit, dispatch, rootState }) {
    Vue.http.get(rootState.application.urls.profile)
      .then(response => {
        commit('SET_USER', response.body)
      })
  },
  setUser ({ commit, dispatch }, attributes) {
    commit('SET_USER', attributes)
  },
  update ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      Vue.http.put(rootState.application.urls.profile, { user: params })
        .then(response => {
          commit('SET_USER', response.body)
          resolve(response.body)
        }, error => {
          reject(error)
        })
    })
  },
}

export const getters = {
  currentUser: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['session/isLoggedIn']) return {}
    // TBD
    return state.user
  },
  hasCurrentUser: (state, getters) => {
    // checking email to detect if currentUser is an empty object
    return getters.currentUser.email !== undefined
  },
  role: (state, getters) => {
    const user = getters.currentUser
    console.log("ROLE " + user.role)
    return user.role
  },
  // isCurrentUserCompleted: (state, getters) => {
  //   return !!getters.currentUser.language
  // },
  fullName: (state, getters) => {
    const user = getters.currentUser
    // return `${user.first_name} ${user.last_name}`
    return [user.first_name, user.last_name].join(' ')
  },
  // fullAddress: (state, getters) => {
  //   if (!!getters.currentUser.address && !!getters.currentUser.cap) {
  //     return [getters.currentUser.address, getters.currentUser.cap].join(', ')
  //   }
  // }
}
