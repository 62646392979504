<template>
  <v-app class="user-app">
    <!--Sidebar v-if="!application.embedded_mode" /-->
    <Header v-if="showHeader" />
    <v-main>
      <Snackbar />
      <Alerts />
      <router-view :key="$route.fullPath" />
    </v-main>
    <Footer v-if="showFooter" />
  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import Header from '../components/layout/header'
import Footer from '../components/layout/footer'
import Alerts from '../components/layout/alerts'
import Snackbar from '../components/layout/snackbar'
import { actionCableMixin } from '../mixins/actioncable_mixin'
import { utilityMixin } from '../mixins/utility_mixin'

export default {
  name: 'App',

  components: {
    Header,
    Footer,
    Alerts,
    Snackbar,
  },

  mixins: [
    utilityMixin,
    actionCableMixin,
  ],

  props: {
    application: Object,
    current_organization: Object,
    options: Object,
    urls: Object,
  },

  computed: {
    ...mapGetters('session', ['isLoggedIn']),
    ...mapGetters('dossier', ['getDossierById']),
    ...mapState('organization', ['organization']),
    ...mapState('application', ['hideHeaderAndFooter']),
    showHeader () {
      return !this.application.embedded_mode && this.hideHeaderAndFooter === false
    },
    showFooter () {
      return !this.application.embedded_mode && this.hideHeaderAndFooter === false
    },
    // currentOrganization() {
    //   return this.isEmptyObject(this.organization) ? this.current_organization : this.organization
    // },
  },

  created () {
    if (!this.isLoggedIn) {
      setBaseColors(this)
    } else {
      setWhiteColors(this)
    }
    Vue.http.interceptors.push(request => {
      if (request.skipInterceptors) return

      // Perchè un clear globale? Non consente di mostrare i messaggi impostati prima del routing
      // this.$store.dispatch('layout/clearAlerts')
      this.$store.dispatch('layout/submitting_request', true)

      return response => {
        this.$store.dispatch('layout/submitting_request', false)

        switch (response.status) {
        case 401:
          if (!this.hasRoutePath('/login')) {
            this.$store.dispatch('layout/clearAlerts')
            this.$store.dispatch('session/logout', { force: true })
          }
          break
        case 500:
          console.log('Catched a 500!', response.body)
          this.$store.dispatch('layout/replaceAlert', {
            type: 'error',
            key: 'error_500',
          })
          break
        case 502:
        case 503:
        case 504:
          console.log('Catched network error!', response.body)
          this.$store.dispatch('layout/replaceAlert', {
            type: 'error',
            key: 'generic_api_error',
          })
          break
        }
      }
    })

    this.$store.dispatch('application/loadInitialProps', {
      application: this.application,
      current_organization: this.current_organization,
      options: this.options,
      urls: this.urls,
    })

    if (this.isLoggedIn) {
      Vue.http.headers.common.Authorization = 'Bearer ' + this.$store.state.session.authToken
      this.$store.dispatch('profile/fetchUser')
      this.$store.dispatch('organization/fetchOrganization', {})
        .then(organization => {
          // console.log(`dossier show id ${this.$route.params.id} dossier ${dossier.id} `)
          // Setup base colors if not a whitelabel
          if (!organization.whitelabel) {
            // Base
            setBaseColors(this)
          }

          // console.log('Whitelabel organizzazione a db: ' + organization.whitelabel)
          if (organization.hash_id) {
            this.$cable.subscribe({
              channel: 'OrganizationsChannel',
              room: organization.hash_id,
            })
          }
        })
    } else {
      setBaseColors(this)
    }
  },
}

function setBaseColors (context) {
  context.$vuetify.theme.light = false
  context.$vuetify.theme.themes.light.primary = '#096a35'
  context.$vuetify.theme.themes.light.secondary = '#21e87e'
  context.$vuetify.theme.themes.light.accent = '#82b1ff'
  context.$vuetify.theme.themes.light.info = '#2196f3'
  context.$vuetify.theme.themes.light.success = '#4caf50'
  context.$vuetify.theme.themes.light.error = '#ff5252'
  context.$vuetify.theme.themes.light.warning = '#fc8e1f'
}

function setWhiteColors (context) {
  // SET Base neutral colors
  context.$vuetify.theme.light = false
  context.$vuetify.theme.themes.light.primary = '#fff' //'#096a35'
  context.$vuetify.theme.themes.light.secondary = '#fff' //'#21e87e'
  context.$vuetify.theme.themes.light.accent = '#fff' //'#82b1ff';
  context.$vuetify.theme.themes.light.info = '#fff' //'#2196f3';
  context.$vuetify.theme.themes.light.success = '#fff' //'#4caf50'
  context.$vuetify.theme.themes.light.error = '#fff' //'#ff5252'
  context.$vuetify.theme.themes.light.warning = '#fff' //'#fc8e1f'
}
</script>
