import Vue from 'vue'

export const namespaced = true

export const state = {
  user: {},
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
}

export const actions = {
  fetchUser ({ commit, dispatch, rootState }) {
    Vue.http.get(rootState.application.urls.profile)
      .then(response => {
        commit('SET_USER', response.body)
      })
  },
  setUser ({ commit, dispatch }, attributes) {
    commit('SET_USER', attributes)
  },
  update ({ commit, dispatch, rootState }, params) {
    return new Promise((resolve, reject) => {
      Vue.http.put(rootState.application.urls.profile, { user: params })
        .then(response => {
          commit('SET_USER', response.body)
          resolve(response.body)
        }, error => {
          reject(error)
        })
    })
  },
  fetchAccounts ({ commit, dispatch, rootState }, params = { page: 1, per_page: 100, sort_by: null, sort_desc: null, search: null }) {
    return new Promise((resolve, reject) => {
      const arParams = Object.keys(params)
        .filter(p => params[p] != null)
        .map(p => `${p}=${params[p]}`)

      let url = rootState.application.urls.users_index
      if (arParams.length > 0) {
        url += `?${arParams.join('&')}`
      }
      Vue.http.get(url, null, {
        responseType: 'json',
      }).then(response => {
        const result = response.data
        resolve(result.signers || result)
      }).catch(error => {
        console.log('fetchSigners There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
  fetchAccount () {},
  createAccount ({ commit, dispatch, rootState }, params) {
    // dispatch('layout/submitting_request', true, { root: true })
    console.log(params)
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.user_create
      console.log(url)
      Vue.http.post(url, params)
        .then(response => {
          const signer = response.body
          console.log(`create signer`, signer)
          // commit('SET_SIGNER', signer)
          // commit('REFRESH_SIGNER_IN_SIGNERS', signer)
          resolve(response)
        }, error => {
          reject(error)
        })
        //.finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  updateAccount ({ commit, dispatch, rootState }, { signerId, params }) {
    //dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.user_update.replace(':id', signerId)
      Vue.http.put(url, params)
        .then(response => {
          const user = response.body
          console.log(`update signer`, user)
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },
  deleteAccount ({ commit, dispatch, rootState }, userId) {
    //dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.user_delete.replace(':id', userId)

      Vue.http.delete(url)
        .then(response => {
          // console.log(`delete signer`, signer_id)
          // commit('REMOVE_SIGNER', signer_id)
          resolve(response)
        }, error => {
          reject(error)
        })
    })
  },

}

export const getters = {
  currentUser: (state, getters, rootState, rootGetters) => {
    if (!rootGetters['session/isLoggedIn']) return {}
    // TBD
    return state.user
  },
  hasCurrentUser: (state, getters) => {
    // checking email to detect if currentUser is an empty object
    return getters.currentUser.email !== undefined
  },
  // isCurrentUserCompleted: (state, getters) => {
  //   return !!getters.currentUser.language
  // },
  fullName: (state, getters) => {
    const user = getters.currentUser
    // return `${user.first_name} ${user.last_name}`
    return [user.first_name, user.last_name].join(' ')
  },
  // fullAddress: (state, getters) => {
  //   if (!!getters.currentUser.address && !!getters.currentUser.cap) {
  //     return [getters.currentUser.address, getters.currentUser.cap].join(', ')
  //   }
  // }
}
