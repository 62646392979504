import { mapState } from 'vuex'

export const homepageContentMixin = {
  computed: {
    ...mapState('application', ['current_organization']),
    // isCurrentOrganizationPresent() {
    //   return !!this.current_organization.name
    // },
    // currentOrganizationName() {
    //   return this.current_organization.name || 'otpservice.io'
    // },
    applicationName () {
      return 'otpservice.io'
    },
    applicationLegalName () {
      return 'Fractalgarden Srl'
    },
    applicationSiteUrl () {
      return 'https://otpservice.io'
    },
    organizationEmail () {
      return this.current_organization.email
    },
    organizationLogoSquareName () {
      // return 'otpservice_logo1_square_bianco_128.png'
      // return 'otp_logo_new.svg';
      return 'logo_otp_nuovo_verde.png'
    },
    organizationLogo () {
      // if (this.current_organization.logo) console.log('ATTENZIONE: caricare il logo per la compagnia ', this.current_organization.id)
      return this.current_organization.logo_url || require(`../../../assets/images/${this.organizationLogoSquareName}`) //
    },
  },
}
