import Vue from 'vue'

export const namespaced = true

export const state = {
  subscription: {},
}

export const mutations = {
  SET_SUBSCRIPTION (state, subscription) {
    state.subscription = subscription
  },
}

export const actions = {
  fetchSubscription ({ commit, dispatch, rootState, state }, { id = 'last', fresh = false }) {
    return new Promise((resolve, reject) => {
      let subscription
      if (!fresh) {
        subscription = state.subscription
      }
      if (subscription && subscription.id) {
        // console.log(`subscription/fetchSubscription retrieved from cache`, subscription)
        resolve(subscription)
      } else {
        // console.log(`subscription/fetchSubscription`)
        dispatch('layout/set_loading', true, { root: true })
        Vue.http.get(rootState.application.urls.subscription_show.replace(':id', id))
          .then(response => {
            subscription = response.body
            // console.log(`- subscription fetched successfully ${subscription.id}`)
            commit('SET_SUBSCRIPTION', subscription)
            dispatch('layout/set_loading', false, { root: true })
            resolve(subscription)
          }, error => {
            dispatch('layout/set_loading', false, { root: true })
            reject(error)
          })
      }
    })
  },
  setSubscription ({ commit, dispatch }, attributes) {
    commit('SET_SUBSCRIPTION', attributes)
  },
}

export const getters = {
  subscription: (state, getters) => {
    return state.subscription
  },
}
