import Vue from 'vue'

export const namespaced = true

export const state = {
}

export const mutations = {
}

export const actions = {
  update ({ commit, dispatch, rootState }, { dossier_id, id, params }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_update.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.put(url, params)
        .then(response => {
          const recipient = response.body
          console.log(`update recipient`, recipient)
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  recognition ({ commit, dispatch, rootState }, { dossier_id, id, params }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_recognition.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.put(url, params)
        .then(response => {
          const recipient = response.body
          console.log(`update recipient recognition`, recipient)
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  retrySendingEmail ({ commit, dispatch, rootState }, { dossier_id, id }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_retry_sending_email.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.post(url)
        .then(response => {
          const recipient = response.body
          console.log(`retry sending email to recipient`, recipient)
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  retrySendUnsignedDocumentMail ({ commit, dispatch, rootState }, { dossier_id, id }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_retry_unsigned_document_email_notification.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.post(url)
        .then(response => {
          const recipient = response.body
          console.log(`retry sending email to recipient`, recipient)
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  retrySendUnsignedDocumentSMS ({ commit, dispatch, rootState }, { dossier_id, id }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_retry_unsigned_document_sms_notification.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.post(url)
        .then(response => {
          const recipient = response.body
          console.log(`retry sending email to recipient`, recipient)
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  retrySendSignedDocumentMail ({ commit, dispatch, rootState }, { dossier_id, id }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_retry_signed_document_email_notification.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.post(url)
        .then(response => {
          const recipient = response.body
          console.log(`retry sending email to recipient`, recipient)
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  unlockOtpCodesGeneration ({ commit, dispatch, rootState }, { dossier_id, id }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_unlock_otp_codes_generation.replace(':dossier_id', dossier_id).replace(':id', id)
      Vue.http.put(url)
        .then(response => {
          const recipient = response.body
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  sendWrongEmailAdvice ({ commit, dispatch, rootState }, uuid) {
    return new Promise((resolve, reject) => {
      Vue.http.post(rootState.application.urls.recipient_report_wrong_email , uuid )
        .then(response => {
          resolve(response.body)
        }, error => {
          reject(error)
        })
    })
  },
  setSignPointsAcceptance ({ commit, dispatch, rootState }, {sp_action, uuid, key }) {
    return new Promise((resolve, reject) => {
      Vue.http.put(rootState.application.urls.update_sign_points_acceptance ,{sp_action, uuid, key })
        .then(response => {
          resolve(response.body)
        }, error => {
          reject(error)
        })
    })
  },
}

export const getters = {
}
