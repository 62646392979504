import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/store'
import NotFound from './pages/not_found'
import NetworkError from './pages/network_error'

Vue.use(VueRouter)

// all routes are private by default
const routes = [
  {
    path: '/accounts',
    name: 'Accounts',
    component: () => import('./pages/accounts'),
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "backend" */ './pages/dashboard'),
  },
  {
    path: '/wrong-email-address/:uuid',
    name: 'WrongEmailAddress',
    component: () => import('./pages/wrong_email_address'),
    props: route => ({
      signer_uuid: route.params.uuid,
    }),
    meta: {
      public: true,
    },
  },
  {
    path: '/terms_and_conditions',
    name: 'TermsAndConditions',
    component: () => import('./pages/home/policy'),
    props: () => ({ policy: 'terms_and_conditions' }),
    meta: {
      public: true,
    },
  },
  {
    path: '/terms_and_conditions_advanced_sign',
    name: 'TermsAndConditionsAdvancedSign',
    component: () => import('./pages/home/policy'),
    props: () => ({ policy: 'terms_and_conditions_advanced_sign' }),
    meta: {
      public: true,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('./pages/home/policy'),
    props: () => ({ policy: 'privacy' }),
    meta: {
      public: true,
    },
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: () => import('./pages/home/policy'),
    props: () => ({ policy: 'cookie' }),
    meta: {
      public: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "homepage" */ './pages/session/login'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () => import(/* webpackChunkName: "homepage" */ './pages/session/sign_up'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/signed-up',
    name: 'signedUp',
    component: () => import(/* webpackChunkName: "homepage" */ './pages/session/signed_up'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/password-reset',
    name: 'passwordReset',
    component: () => import(/* webpackChunkName: "service" */ './pages/session/password_reset'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/new-password',
    name: 'newPassword',
    component: () => import(/* webpackChunkName: "service" */ './pages/session/new_password'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/confirmation-email',
    name: 'confirmationEmail',
    component: () => import(/* webpackChunkName: "service" */ './pages/session/confirmation_email'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
    },
  },
  {
    path: '/user_profile',
    name: 'showUserProfile',
    component: () => import(/* webpackChunkName: "backend" */ './pages/profile/show_user'),
  },
  {
    path: '/organization',
    name: 'showOrganizationProfile',
    component: () => import(/* webpackChunkName: "backend" */ './pages/organization/show'),
  },
  {
    path: '/child-organizations',
    name: 'childOrganizations',
    component: () => import(/* webpackChunkName: "backend" */ './pages/child_organizations'),
  },
  {
    path: '/accept-child-organization-link',
    name: 'acceptChildOrganizationLink',
    component: () => import(/* webpackChunkName: "backend" */ './pages/accept_child_organization_link'),
  },
  {
    path: '/invoice-account',
    name: 'showInvoicingProfile',
    component: () => import(/* webpackChunkName: "backend" */ './pages/invoice_account/show'),
  },
  {
    path: '/ledger_book',
    name: 'showLedgerBook',
    component: () => import(/* webpackChunkName: "backend" */ './pages/profile/show_ledger_book'),
  },
  {
    path: '/conservazione_digitale',
    name: 'showConservazioneDigitale',
    component: () => import(/* webpackChunkName: "backend" */ './pages/profile/show_conservazione_digitale'),
  },
  {
    path: '/api_password',
    name: 'showApiPassword',
    component: () => import(/* webpackChunkName: "backend" */ './pages/profile/show_api_password'),
  },
  {
    path: '/edit-profile',
    name: 'editProfile',
    component: () => import(/* webpackChunkName: "backend" */ './pages/profile/edit'),
  },
  {
    path: '/edit-organization',
    name: 'editOrganization',
    component: () => import(/* webpackChunkName: "backend" */ './pages/organization/edit'),
  },
  {
    path: '/edit-conservazione_digitale',
    name: 'editConservazioneDigitale',
    component: () => import(/* webpackChunkName: "backend" */ './pages/organization/edit_conservazione_digitale'),
  },
  {
    path: '/edit-invoice-account',
    name: 'editInvoiceAccount',
    component: () => import(/* webpackChunkName: "backend" */ './pages/invoice_account/edit'),
  },
  {
    path: '/sign/recipient/:uuid',
    name: 'SignDocument',
    component: () => import(/* webpackChunkName: "sign" */ './pages/sign_document'),
    meta: {
      public: true,
    },
  },
  {
    path: '/sign/:uuid',
    redirect: { name: 'SignDocument' },
    meta: {
      public: true,
    },
  },
  // ATTENZIONE: il path /signature viene usato per comporre il link nell'email app/mailers/recipient_mailer.rb:27
  {
    path: '/signature',
    name: 'Signature',
    component: () => import(/* webpackChunkName: "check_signature" */ './pages/signature'),
    meta: {
      public: true,
    },
  },
  {
    path: '/dossiers',
    name: 'DossiersIndex',
    component: () => import(/* webpackChunkName: "backend" */ './pages/dossiers_index'),
    props: route => ({
      initial_page: parseInt(route.query.page),
      initial_per_page: parseInt(route.query.per_page || 0),
      initial_sort_by: route.query.sort_by,
      initial_sort_desc: route.query.sort_desc,
    }),
  },
  {
    path: '/dossiers/new',
    name: 'DossierNew',
    component: () => import(/* webpackChunkName: "backend" */ './pages/dossier_new'),
    props: true,
  },
  {
    path: '/dossiers/:id',
    name: 'DossierShow',
    component: () => import(/* webpackChunkName: "backend" */ './pages/dossier_show'),
  },
  {
    path: '/dossiers/:id/edit',
    name: 'DossierEdit',
    component: () => import(/* webpackChunkName: "backend" */ './pages/dossier_edit'),
  },
  {
    path: '/dossiers/:id/recipients/:recipient_id',
    name: 'RecipientShow',
    component: () => import(/* webpackChunkName: "backend" */ './pages/recipient_show'),
  },
  {
    path: '/signers',
    name: 'SignersIndex',
    component: () => import(/* webpackChunkName: "backend" */ './pages/signers_index'),
    props: route => ({
      initial_page: parseInt(route.query.page),
      initial_per_page: parseInt(route.query.per_page),
      initial_sort_by: route.query.sort_by,
      initial_sort_desc: route.query.sort_desc,
      fun: route.query.fun,
    }),
  },
  {
    path: '/signers/:id',
    name: 'SignerShow',
    component: () => import(/* webpackChunkName: "backend" */ './pages/signer_show'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "backend" */ './pages/shop'),
  },
  {
    path: '/payments/:id',
    name: 'PaymentShow',
    component: () => import(/* webpackChunkName: "backend" */ './pages/payment_show'),
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound,
    props: true,
    meta: {
      public: true,
    },
  },
  {
    path: '/network-error',
    name: 'NetworkError',
    component: NetworkError,
    props: true,
    meta: {
      public: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'NotFound' },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    // console.log(`scrollBehavior savedPosition`, savedPosition, to)
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  const authToken = to.query.authToken

  if (authToken) {
    // auth with token passed as query params
    // used, for example, in admin impersonate feature
    store.dispatch('session/loginFromAuthToken', authToken)
    return next()
  }

  if (!isPublic(to) && !isLoggedIn()) {
    store.dispatch('layout/replaceAlert', {
      type: 'warning',
      key: 'unauthorized',
    })

    return next({
      path: '/login',
      query: {
        redirectTo: to.fullPath, // Store the full path to redirect the user to after login
      },
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (isLoggedIn() && onlyWhenLoggedOut(to)) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.query.redirectTo) {
    // coming from a private page, let's redirect to login and keep alert
  } else {
    store.dispatch('layout/clearAlerts')
  }

  next()
})

const isPublic = function (to) {
  return to.matched.some(record => record.meta.public)
}

const onlyWhenLoggedOut = function (to) {
  return to.matched.some(record => record.meta.onlyWhenLoggedOut)
}

const isLoggedIn = function () {
  return store.getters['session/isLoggedIn']
}

export default router
