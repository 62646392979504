<template>
  <v-container class="center">
    <div class="text-center">
      <h1 class="display-3">
        {{ error_number }}
      </h1>
      <h3>{{ message }}</h3>
      <v-btn
        class="mt-5"
        @click="$router.go(-2)"
      >
        {{ $t('commons.back') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',

  props: {
    message_string: {
      type: String,
    },
    message_key: {
      type: String,
      default: 'page_not_found',
    },
    error_number: {
      type: String,
      default: '404'
    },
  },

  computed: {
    message () {
      return this.message_string || this.$t(`alerts.${this.message_key}`)
    },
  },
}
</script>

<style scoped lang="scss">
  .center {
    display: grid;
    place-items: center;
    height: 70vh;
  }
</style>
