import Vue from 'vue'

export const namespaced = true

export const state = {
  signers: [],
  signer: {},
  signerPageCount: null,
}

export const mutations = {
  SET_SIGNERS (state, signers) {
    state.signers = signers
  },
  SET_SIGNER (state, signer) {
    state.signer = signer
  },
  // After updates use this mutation to refresh the single item into the cache
  REFRESH_SIGNER_IN_SIGNERS (state, signer = state.signer) {
    // console.log('Refresh signers, currently items:', state.signers.length)
    const indexToReplace = state.signers.findIndex(obj => obj.id == signer.id)
    if (indexToReplace != -1) {
      // console.log(' - replace old with newest, id', signer.id)
      state.signers[indexToReplace] = signer
      // this is for vue reaction
      state.signers.push({})
      state.signers.splice(-1, 1)
    } else {
      // Add new item at the beginning
      state.signers.unshift(signer)
    }
    // console.log('  - replaced! New items:', state.signers.length)
  },
  ADD_SIGNER (state, signer) {
    state.signers.push(signer)
  },
  REMOVE_SIGNER (state, id) {
    const indexToRemove = state.signers.findIndex(signer => signer.id == id)
    if (indexToRemove != -1) {
      state.signers.splice(indexToRemove, 1)
    }
  },
  SET_PAGE_COUNT (state, value) {
    state.signerPageCount = value
  },
}

export const actions = {
  fetchSigners ({ commit, dispatch, rootState }, params = { page: 1, per_page: 10, sort_by: null, sort_desc: null, search: null }) {
    return new Promise((resolve, reject) => {
      dispatch('layout/set_loading', true, { root: true })
      // console.log(`fetchSigners rootState ${rootState.application.urls.signers_index}`)
      const arParams = Object.keys(params)
        .filter(p => params[p] != null)
        .map(p => `${p}=${params[p]}`)
      // let url = `${rootState.application.urls.signers_index}?page=${params.page}&per_page=${params.perPage}`
      let url = rootState.application.urls.signers_index
      if (arParams.length > 0) url += `?${arParams.join('&')}`
      Vue.http.get(url, null, {
        responseType: 'json',
      }).then(response => {
        const result = response.data
        commit('SET_SIGNERS', result.signers)
        commit('SET_PAGE_COUNT', result.page_count)
        dispatch('layout/set_loading', false, { root: true })
        resolve(result.signers)
      }).catch(error => {
        dispatch('layout/set_loading', false, { root: true })
        console.log('fetchSigners There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
  // USATA NEL FORM RECIPIENT PER CERCARE I FIRMATARI DALLA SELECT BOX
  searchSigners ({ commit, dispatch, rootState }, params = { page: 1, per_page: 10, sort_by: null, sort_desc: null, search: null }) {
    return new Promise((resolve, reject) => {
      // console.log(`fetchSigners rootState ${rootState.application.urls.signers_index}`)
      const arParams = Object.keys(params)
        .filter(p => params[p] != null)
        .map(p => `${p}=${params[p]}`)
      // let url = `${rootState.application.urls.signers_index}?page=${params.page}&per_page=${params.perPage}`
      let url = rootState.application.urls.signers_index
      if (arParams.length > 0) url += `?${arParams.join('&')}`
      Vue.http.get(url, null, {
        responseType: 'json',
      }).then(response => {
        const result = response.data
        resolve(result.signers || result)
      }).catch(error => {
        console.log('fetchSigners There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
  fetchSigner ({ commit, dispatch, getters, rootState }, { id, fresh = false }) {
    return new Promise((resolve, reject) => {
      // console.log(`signer/fetchSigner id ${id} fresh ${fresh}`)
      let signer
      if (!fresh) {
        signer = getters.getSignerById(id)
        // console.log(`  - signer retrieved from cache`, signer)
      }
      if (signer) {
        // Rimpiazziamo signer con quello dell'id ricercato
        commit('SET_SIGNER', signer)
        resolve(signer)
      } else {
        dispatch('layout/set_loading', true, { root: true })
        const url = rootState.application.urls.signer_show.replace(':id', id)
        return Vue.http.get(url, null, {
          responseType: 'json',
        }).then(response => {
          signer = response.data
          commit('SET_SIGNER', signer)
          commit('REFRESH_SIGNER_IN_SIGNERS', signer)
          dispatch('layout/set_loading', false, { root: true })
          resolve(signer)
        })
          .catch(error => {
            console.log('fetchSigner There was an error:', error.response ? error.response : error)
            dispatch('layout/set_loading', false, { root: true })
            reject(error)
          })
      }
    })
  },
  create ({ commit, dispatch, rootState }, params) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signers_index
      const formData = formDataFromParams(params)
      Vue.http.post(url, formData)
        .then(response => {
          const signer = response.body
          console.log(`create signer`, signer)
          commit('SET_SIGNER', signer)
          commit('REFRESH_SIGNER_IN_SIGNERS', signer)
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  update ({ commit, dispatch, rootState }, { signer_id, params }) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signer_show.replace(':id', signer_id)
      const formData = formDataFromParams(params)
      Vue.http.put(url, formData)
        .then(response => {
          const signer = response.body
          console.log(`update signer`, signer)
          commit('SET_SIGNER', signer)
          commit('REFRESH_SIGNER_IN_SIGNERS', signer)
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  delete ({ commit, dispatch, rootState }, signer_id) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signer_show.replace(':id', signer_id)

      Vue.http.delete(url)
        .then(response => {
          console.log(`delete signer`, signer_id)
          commit('REMOVE_SIGNER', signer_id)
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
}

const formDataFromParams = (params) => {
  const formData = new FormData()
  for (const [key, val] of Object.entries(params)) {
    if (val === null || typeof val === 'undefined') { continue }
    formData.append(key, val)
  }
  return formData
}

export const getters = {
  getSignerById: state => id => {
    return state.signers.find(signer => signer.id == id)
  },
}
