<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :color="snackbar.color"
    :multi-line="snackbar.multiLine"
    :top="snackbar.top"
    :left="snackbar.left"
    :right="snackbar.right"
  >
    {{ snackbar.text }}
    <v-btn
      text
      @click="show = false"
    >
      {{ $t('commons.close') }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('layout', ['snackbar']),
    timeout () {
      return this.snackbar.timeout || (this.snackbar.color == 'error' ? 8000 : 4000)
    },
    show: {
      get () {
        return this.snackbar.show
      },
      set (value) {
        this.$store.dispatch('layout/setSnackbar', {
          show: value,
        })
      },
    },
  },
}
</script>
