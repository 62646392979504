<template>
  <v-footer
    color="primary lighten-1 py-1 white--text"
    padless
  >
    <v-row
      justify="center"
      no-gutters
      class="footer-otp"
    >
      <v-col
        class="text-center footer-texts"
        cols="12"
        sm="12"
        md="4"
        align="center"
      >
        <strong class="white--text">
          {{ application.name }}
        </strong>
        v{{ application.version }} - ©{{ new Date().getFullYear() }}
        <br> Servizio di <a :style="{color: 'white'}" href="https://www.fractalgarden.com/">Fractalgarden Srl</a><br>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        align="center"
        class="footer-logo-col"
      >
        <a href="https://www.otpservice.io">
          <v-img
            :src="require('../../../../assets/images/otp_logo_new.svg')"
            height="80px"
            width="130px"
          />
        </a>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="link-box"
        align="center"
      >
        <v-list class="link-list">
          <v-list-item
            v-if="isLoggedIn"
            class="link-item"
            :to="{name: 'Privacy'}"
          >
            {{ $t('home.info.privacy_policy') }}
          </v-list-item>

          <v-list-item
            v-if="isShopAnchorVisible"
            class="link-item"
            :to="{name: 'Shop'}"
          >
            {{ $t('sidebar.shop') }}
          </v-list-item>

          <v-list-item
            v-if="supportEmail"
            class="link-item"
            @click="askForSupport"
          >
            {{ $t('sidebar.help') }}
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('application', ['application', 'current_organization']),
    ...mapState('organization', ['organization']),
    ...mapGetters('profile', ['currentUser']),
    ...mapGetters('session', ['isLoggedIn']),
    supportLink() {
      return `mailto:${this.supportEmail}?subject=${this.$t('footer.support_email.subject')}`
    },
    supportEmail() {
      return this.isLoggedIn ? this.current_organization.email : this.organization?.support_email || this.organization?.email
    },
    isShopAnchorVisible() {
      return this.isLoggedIn && this.currentUser?.abilities?.canUpdateInvoiceAccount
    },
  },

  methods: {
    askForSupport () {
      window.open(this.supportLink)
    },
  },
}
</script>

<style lang="scss">
.footer-otp{
  height: auto;
  padding: 0em;
}

.footer-texts {
  padding: 2em !important;
}

.footer-logo-col {
  padding: 1em !important;
}

.link-box {
  margin-top: 1.5em;
}

.link-list, .link-item{
  color: white !important;
  background-color: transparent !important;
  background: transparent !important;
  padding: 0 !important;
  margin:0 !important;
  min-height: auto !important;
  width: 200px !important;
}
</style>
