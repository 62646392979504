import Vue from 'vue'
import Vuex from 'vuex'
import * as application from './modules/application'
import * as session from './modules/session'
import * as profile from './modules/profile'
import * as organization from './modules/organization'
import * as subscription from './modules/subscription'
import * as layout from './modules/layout'
import * as signature from './modules/signature'
import * as dossier from './modules/dossier'
import * as signPointsTemplate from './modules/sign_points_template'
import * as recipient from './modules/recipient'
import * as signer from './modules/signer'
import * as product from './modules/product'
import * as payment from './modules/payment'
import * as mappings from './modules/mappings'
import * as statistics from './modules/statistics'
import * as users from './modules/users'
import * as ledgers from './modules/ledgers'
import * as conservazioneDigitale from './modules/conservazioneDigitale'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    application,
    session,
    profile,
    organization,
    subscription,
    layout,
    signature,
    dossier,
    signPointsTemplate,
    recipient,
    signer,
    product,
    payment,
    mappings,
    statistics,
    users,
    ledgers,
    conservazioneDigitale,
  },
  state: {},
  strict: debug,
})
