import Vue from 'vue'
import storeOptions from '../store_options'

export const namespaced = true

export const state = {
  payment: null,
}

export const mutations = {
  SET_PAYMENT (state, payment) {
    state.payment = payment
  },
  CLEAR_PAYMENT (state, current_payment) {
    state.payment = null
  },
}

export const actions = {
  fetchPayment ({ commit, dispatch, rootState, state }, { id, fresh = false }) {
    return new Promise((resolve, reject) => {
      let payment
      if (!fresh) {
        payment = state.payment
      }

      if (payment && payment.id) {
        // console.log(`payment/fetchPayment retrieved from cache`, payment)
        resolve(payment)
      } else {
        // console.log(`payment/fetchPayment`)
        dispatch('layout/set_loading', true, { root: true })
        const url = rootState.application.urls.payment_show.replace(':id', id)
        return Vue.http.get(url, null, {
          responseType: 'json',
          timeout: storeOptions.timeout,
        })
          .then(response => {
            payment = response.body
            // console.log(`- payment fetched successfully ${payment.id}`)
            commit('SET_PAYMENT', payment)
            dispatch('layout/set_loading', false, { root: true })
            resolve(payment)
          }, error => {
            dispatch('layout/set_loading', false, { root: true })
            reject(error)
          })
      }
    })
  },
  setPayment ({ commit }, attributes) {
    commit('SET_PAYMENT', attributes)
  },
  create ({ commit, dispatch, rootState }, params) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.payments_index

      Vue.http.post(url, params)
        .then(response => {
          const payment = response.body
          console.log(`create payment`, payment)
          commit('SET_PAYMENT', payment)
          resolve(payment)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  // update({commit, dispatch, rootState}, {payment_id, params}) {
  //   dispatch('layout/submitting_request', true, {root: true})
  //   return new Promise((resolve, reject) => {
  //     const url = rootState.application.urls.payment_show.replace(':id', payment_id)
  //
  //     Vue.http.put(url, params)
  //       .then(response => {
  //         const payment = response.body
  //         console.log(`update payment`, payment)
  //         commit('SET_PAYMENT', payment)
  //         resolve(response)
  //       }, error => {
  //         reject(error)
  //       })
  //       .finally(() => (dispatch('layout/submitting_request', false, {root: true})))
  //   })
  // },
  pay_with_nexi ({ commit, dispatch, rootState }, params) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      console.log('pay_with_nexi', params)
      const formData = formDataFromParams(params)
      const url = rootState.application.urls.pay_with_nexi
      console.log(' - url nexi', url)
      Vue.http.post(url, formData)
        .then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
}

export const getters = {
  paymentName: (state, getters) => {
    return state.payment.name
  },
}

function formDataFromParams (params) {
  const formData = new FormData()

  for (const [key, value] of Object.entries(params)) {
    // console.log(`${key}: ${value}`)
    formData.append(key, value || '')
  }

  return formData
}
