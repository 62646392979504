export const namespaced = true

export const state = {
  application: {},
  urls: {},
  current_organization: {},
  hideHeaderAndFooter: false,
}

export const mutations = {
  LOAD_INITIAL_PROPS (state, initialProps) {
    for (const key in initialProps) {
      state[key] = initialProps[key]
    }
  },
  SET_HIDE_HEADER_AND_FOOTER (state, value) {
    state.hideHeaderAndFooter = value === true
  },
}

export const actions = {
  loadInitialProps ({ commit }, initialProps) {
    commit('LOAD_INITIAL_PROPS', initialProps)
  },
  setHideHeaderAndFooter ({ commit }, value) {
    commit('SET_HIDE_HEADER_AND_FOOTER', value)
  },
}

export const getters = {
}
