import Vue from 'vue'

export const namespaced = true

export const state = {
  statistics_dossier_week: {},
  statistics_dossier_month: {},
  statistics_dossier_year: {},
}

export const mutations = {
  SET_STATISTICS_DOSSIER (state, { statistics, period }) {
    // console.log(`SET_STATISTICS_DOSSIER ${period}`, statistics)
    state[`statistics_dossier_${period}`] = statistics
  },
  CLEAR_STATISTICS (state) {
    state.statistics_dossier_week = {}
    state.statistics_dossier_month = {}
    state.statistics_dossier_year = {}
  },
}

export const actions = {
  fetchStatisticsDossier ({ commit, dispatch, rootState, state }, { period = 'month', fresh = false }) {
    return new Promise((resolve, reject) => {
      let statistics
      if (!fresh) {
        statistics = state[`statistics_dossier_${period}`]
      }

      if (statistics && !isEmptyObject(statistics)) {
        // console.log(`fetchStatisticsDossier retrieved from cache`, statistics)
        resolve(statistics)
      } else {
        // console.log(`fetchStatisticsDossier`)
        dispatch('layout/set_loading', true, { root: true })
        Vue.http.get(rootState.application.urls.statistics_dossier.replace(':period', period))
          .then(response => {
            statistics = response.body
            // console.log(`- statistic fetched successfully`)
            commit('SET_STATISTICS_DOSSIER', { statistics, period })
            dispatch('layout/set_loading', false, { root: true })
            resolve(statistics)
          }, error => {
            dispatch('layout/set_loading', false, { root: true })
            reject(error)
          })
      }
    })
  },
  clearStatistics ({ commit }) {
    commit('CLEAR_STATISTICS')
  },
}

function isEmptyObject (obj) {
  // return Object.keys(obj ?? {}).length === 0
  let name
  for (name in obj) { return false }
  return true
}

export const getters = {
}
