import Vue from 'vue'

import App from './pages/index'
import store from './store/store'
import router from './router'

import VueResource from 'vue-resource'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'

import moment from 'moment'
import vuetify from './plugins/vuetify'
import DayjsPlugin from './plugins/dayjs'
import ActionCableVue from 'actioncable-vue'
import Rollbar from 'rollbar'

import './plugins'

import './assets/stylesheets/application.scss'

Vue.use(VueResource)
Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(DayjsPlugin)

const init = function () {
  const el = document.getElementById('users-vue-wrapper')
  if (el !== null) {
    const id = '#' + el.getAttribute('id')
    const props = JSON.parse(el.getAttribute('data-props'))

    if (props?.options?.websocketUrl) {
      Vue.use(ActionCableVue, {
        debug: true,
        debugLevel: 'error',
        connectionUrl: props.options.websocketUrl, // or function which returns a string with your JWT appended to your server URL as a query parameter
        connectImmediately: true,
        store,
      })
    } else {
      console.error('Please set props -> options -> websocketUrl')
    }

    if (props?.options?.rollbarPostClientItemToken) {
      Vue.prototype.$rollbar = new Rollbar({
        accessToken: props.options.rollbarPostClientItemToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: props?.application?.environment,
          code_version: props?.application?.version,
        },
      })

      // If you have already set up a global error handler,
      // just add `vm.$rollbar.error(err)` to the top of it.
      // If not, this simple example will preserve the app’s existing
      // behavior while also reporting uncaught errors to Rollbar.
      Vue.config.errorHandler = (err, vm, info) => {
        vm.$rollbar.error(err)
        throw err // rethrow
      }
    }

    const locale = localStorage.getItem('userLocale') || el.getAttribute('data-locale') || 'it'
    Vue.http.headers.common['Accept-Language'] = locale
    moment.locale(locale)

    const i18n = new VueI18n({
      locale,
      messages: JSON.parse(el.getAttribute('data-i18n')),
    })

    window.uiApp = new Vue({
      i18n,
      vuetify,
      store,
      router,
      el: id,
      render: h => h(App, {
        props,
      }),
    })
  }
}

Vue.prototype.$super = function (options) {
  return new Proxy(options, {
    get: (options, name) => {
      if (options.methods && name in options.methods) {
        return options.methods[name].bind(this)
      }
    },
  })
}

export default {
  init,
}
