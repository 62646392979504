import Vue from 'vue'
import storeOptions from '../store_options'

export const namespaced = true

export const state = {
  signPointsTemplates: [],
}

export const mutations = {
  SET_SIGN_POINTS_TEMPLATES (state, signPointsTemplates) {
    state.signPointsTemplates = signPointsTemplates
  },
}

export const actions = {
  fetchSignPointsTemplates ({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      const arrayIn = []
      let url = rootState.application.urls.sign_points_templates_index
      // console.log(`fetchSignPointsTemplates. url ${url}`)
      Vue.http.get(url, null, {
        responseType: 'json',
        timeout: storeOptions.timeout,
      }).then(response => {
        const signPointsTemplates = response.data
        commit('SET_SIGN_POINTS_TEMPLATES', signPointsTemplates)
        resolve(signPointsTemplates)
      }).catch(error => {
        console.log('fetchSignPointsTemplates There was an error:', error.response ? error.response : error)
        reject(error)
      })
    })
  },
  createSignPointsTemplate ({ commit, dispatch, rootState }, params) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.sign_points_templates_create
      Vue.http.post(url, params, { timeout: storeOptions.timeout })
        .then(response => {
          const signPointsTemplates = response.data
          //console.log(`create signPointsTemplate`, signPointsTemplates)
          commit('SET_SIGN_POINTS_TEMPLATES', signPointsTemplates)
          resolve(signPointsTemplates)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  removeSignPointsTemplate ({ commit, dispatch, rootState }, signPointsTemplate_id) {
    dispatch('layout/submitting_request', true, { root: true })
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.sign_points_templates_delete
      const data = JSON.stringify({id: signPointsTemplate_id})
      console.log(`delete signPointsTemplate ${url}, body: ${data}`)
      Vue.http.post(
        url,
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      ).then(response => {
        const signPointsTemplates = response.data
        commit('SET_SIGN_POINTS_TEMPLATES', signPointsTemplates)
        resolve(signPointsTemplates)
      }, error => {
        reject(error)
      })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
}

function formDataFromParams (params) {
  const formData = new FormData()

  formData.append("name", params.name)
  formData.append("pages_count", params.pages_count)

  for (let i = 0; i < params.sign_points.length; i++) {
    formData.append(`sign_points[${i}][group]`, params.sign_points[i].group)
    formData.append(`sign_points[${i}][label]`, params.sign_points[i].label)
    formData.append(`sign_points[${i}][page]`, params.sign_points[i].page)
    formData.append(`sign_points[${i}][top]`, params.sign_points[i].top)
    formData.append(`sign_points[${i}][left]`, params.sign_points[i].left)
    formData.append(`sign_points[${i}][required]`, params.sign_points[i].required)
  }

  return formData
}

function isEmptyObject (obj) {
  // return Object.keys(obj ?? {}).length === 0
  let name
  for (name in obj) { return false }
  return true
}
