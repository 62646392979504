import Vue from 'vue'

export const namespaced = true

export const state = {
  signatures: [],
  signature: {},
}

export const mutations = {
  ADD_TO_SIGNATURES (state, signature) {
    // Add only if not present
    // if (!getters.getSignatureByAttributes(signature.checksum, signature.phone)) {
    //   state.signatures.push(signature)
    // }
    state.signatures.push(signature)
  },
  SET_SIGNATURE (state, signature) {
    state.signature = signature
  },
}

export const actions = {
  updateReadDisclaimerInfo ({ dispatch, rootState }, { uuid, accepted }) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.recipient_update_read_disclaimer_info.replace(':uuid', uuid)
      dispatch('layout/submitting_request', true, { root: true })
      Vue.http.put(url, { accepted })
        .then(response => {
          const recipient = response.data
          // dispatch('layout/set_loading', false, { root: true })
          resolve(recipient)
          // console.log(response)
        }, error => {
          // console.log(error)
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  fetchSignature ({ commit, dispatch, getters, rootState }, { checksum, fresh = false }) {
    return new Promise((resolve, reject) => {
      // console.log(`signature/fetchSignature id ${checksum} fresh ${fresh}`)
      let signature
      if (!fresh) {
        signature = getters.getSignatureByAttributes(checksum)
        signature ? console.log(`  - signature retrieved from cache`, signature) : console.log(`  - signature not present in cache`)
      }
      if (signature) {
        // Rimpiazziamo signature con quello dell'id ricercato
        commit('SET_SIGNATURE', signature)
        resolve(signature)
      } else {
        dispatch('layout/set_loading', true, { root: true })
        const url = rootState.application.urls.signatures_checksum.replace(':checksum', checksum)
        return Vue.http.get(url, null, {
          responseType: 'json',
        }).then(response => {
          signature = response.data
          commit('SET_SIGNATURE', signature)
          commit('ADD_TO_SIGNATURES', signature)
          resolve(signature)
        })
          .catch(error => {
            console.log('fetchSignature There was an error:', error.response ? error.response : error)
            reject(error)
          })
          .finally(() => dispatch('layout/set_loading', false, { root: true }))
      }
    })
  },
  fetchRecipient ({ dispatch, rootState }, { uuid }) {
    return new Promise((resolve, reject) => {
      // console.log(`signature/fetchRecipient uuid ${uuid}`)
      dispatch('layout/set_loading', true, { root: true })
      const url = rootState.application.urls.signatures_recipient.replace(':uuid', uuid)
      // Mount a fake port with netcat: nc -l 8099
      // let url = 'http://localhost:8099/some/sort/of/endpoint'
      return Vue.http.get(url, null, {
        responseType: 'json',
        timeout: 5000,
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          console.log('fetchRecipient There was an error:', error.response ? error.response : error)
          reject(error)
        })
        .finally(() => dispatch('layout/set_loading', false, { root: true }))
    })
  },
  sendOtp ({ dispatch, rootState }, { uuid, otpChannel }) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signatures_send_otp_to_recipient.replace(':uuid', uuid)
      dispatch('layout/submitting_request', true, { root: true })
      Vue.http.put(url, { otp_channel: otpChannel })
        .then(response => {
          const recipient = response.data
          dispatch('layout/set_loading', false, { root: true })
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  verifyOtp ({ dispatch, rootState }, { uuid, otp }) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signatures_verify_otp_from_recipient.replace(':uuid', uuid)
      dispatch('layout/submitting_request', true, { root: true })
      const timestamp = ((Date.now() / 1000 | 0) + 10000).toString()
      Vue.http.put(url, { otp, timestamp })
        .then(response => {
          const recipient = response.data
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  updateRecipient ({ dispatch, rootState }, { uuid, attributes }) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signatures_update_recipient.replace(':uuid', uuid)
      dispatch('layout/submitting_request', true, { root: true })
      Vue.http.put(url, attributes)
        .then(response => {
          const recipient = response.data
          resolve(recipient)
        }, error => {
          // TODO Handle the error, ask the user to send the picture again
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  updateMarkedSigns ({ dispatch, rootState }, { uuid, marked_signs, final_acceptance }) {
    return new Promise((resolve, reject) => {
      const url = rootState.application.urls.signatures_update_recipient_marked_signs.replace(':uuid', uuid)
      dispatch('layout/submitting_request', true, { root: true })
      Vue.http.put(url, { marked_signs, final_acceptance })
        .then(response => {
          // Col json sign si doveva cercare il recipient nel json, ora è stato semplificato
          // const recipient = response.data.recipients.find(r => r.uuid == uuid)
          const recipient = response.data
          resolve(recipient)
        }, error => {
          reject(error)
        })
        .finally(() => (dispatch('layout/submitting_request', false, { root: true })))
    })
  },
  blankSignature ({ commit }, {}) {
    commit('SET_SIGNATURE', null)
  },
}

export const getters = {
  getSignatureByAttributes: state => (checksum) => {
    return state.signatures.find(signature => signature.checksum == checksum)
  },
}
