// It manages channels subscriptions
//  - see index.vue for organization channel
// It needs organization state from vuex to get room hash
//  ...mapState('organization', ['organization']),

export const actionCableMixin = {
  channels: {
    // It needs:
    // - anyDossierUpdated defined in data
    // - the getter getDossierById
    OrganizationsChannel: {
      connected () { console.log(`Connected to OrganizationsChannel ${this.organization.id}`) },
      rejected () { console.log(`Left the OrganizationsChannel :-(`) },
      received (data) {
        console.log(`Stream from OrganizationsChannel:`, data)
        switch (data.operation) {
          case 'update':
            if (data.recipient) {
              this.$store.dispatch('dossier/updateRecipientInDossier', data.recipient)
            } else if (data.dossier) {
              this.$store.dispatch('dossier/updateDossierInDossiers', data.dossier)
            }
            break
          case 'create':
            if (data.recipient) {
              // Check recipient doppio...
              // this.$store.dispatch('dossier/updateRecipientInDossier', data.recipient)
            } else if (data.dossier) {
              // If dossier is NOT present in the list...
              setTimeout(() => {
                this.$store.dispatch('dossier/setDossiersChanged', { isRemoved: false, dossier: data.dossier })
              }, 2000)
              // this.setRefreshAfterUpdate(false, data.dossier.id)
            }
            break
          case 'delete':
            if (data.recipient) {
              this.$store.dispatch('dossier/updateRecipientInDossier', data.recipient)
            } else if (data.dossier) {
              // If dossier is present in the list...
              setTimeout(() => {
                this.$store.dispatch('dossier/setDossiersChanged', { isRemoved: true, dossier: data.dossier })
              }, 2000)
              // this.setRefreshAfterUpdate(true, data.dossier.id)
            }
            break
        }
      },
      disconnected () { console.log(`Disconnected from OrganizationsChannel`) },
    },
  },
}
